import React from 'react'
import { HeroBlock } from '@blocks'

// import * as styles from './hero.module.scss'

const Hero = ({ title, image }) => {
  return (
    <HeroBlock
      image={image}
      titleTop={title ? title : 'Read Our Latest Articles'}
      body='Check out the latest news and tips about web development and growing your small business.'
      buttons={false}
      sm
    />
  )
}

export default Hero
