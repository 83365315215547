import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Layout, Seo, Cta } from '@global'
import { Hero, PostGrid } from '@pages/blog/collection'
import { ImageCard } from '@UI'

const TagCollection = ({ data }) => {
  return (
    <Layout>
      <Seo
        title={`Articles tagged "${data.tagName.data.tag_name.text}" | Stellr Web | Cody, WY`}
        description={`View all our articles related to "${data.tagName.data.tag_name.text}".`}
      />

      <Helmet encodeSpecialCharacters={false}>
        <script type='application/ld+json'>
          {`
						{
							"@context": "https://schema.org/",
							"@type": "BreadcrumbList",
							"itemListElement": [{
								"@type": "ListItem",
								"position": 1,
								"name": "${data.tagName.data.tag_name.text} Posts",
								"item": "https://www.stellrweb.com/tags/${data.tagName.uid}"
							}]
						}
        `}
        </script>
      </Helmet>

      <Hero
        title={`Read Articles Related to ${data.tagName.data.tag_name.text}`}
        image={data.heroImage}
      />
      <PostGrid>
        {data.blogTagsPageQuery.edges.map(({ node }) => {
          const post = node.data
          const tags = node.data.post_tags.map(({ tag }) => ({
            uid: tag.uid,
            label: tag.document.data.tag_name.text,
          }))

          return (
            <ImageCard
              title={post.blog_title.text}
              excerpt={post.excerpt.text}
              image={post.cover_image}
              tags={tags}
              link={`/blog/${node.uid}`}
              key={node.id}
            />
          )
        })}
      </PostGrid>
      <Cta />
    </Layout>
  )
}

export default TagCollection

export const pageQuery = graphql`
  query TagCollection($skip: Int!, $limit: Int!, $tag: String!) {
    heroImage: file(
      sourceInstanceName: { eq: "images" }
      name: { eq: "tag-hero" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 100, placeholder: BLURRED)
      }
    }
    tagName: prismicTags(uid: { eq: $tag }) {
      uid
      data {
        tag_name {
          text
        }
      }
    }
    blogTagsPageQuery: allPrismicBlogPosts(
      filter: {
        data: { post_tags: { elemMatch: { tag: { uid: { eq: $tag } } } } }
      }
      sort: { order: DESC, fields: [data___publish_date] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          uid
          id
          data {
            blog_title {
              text
            }
            excerpt {
              text
            }
            cover_image {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 700
                placeholder: BLURRED
              )
            }
            post_tags {
              tag {
                id
                uid
                document {
                  ... on PrismicTags {
                    id
                    data {
                      tag_name {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
