import React from 'react'
import { Container } from '@UI'

import * as styles from './postGrid.module.scss'

const PostGrid = ({ children }) => {
  return (
    <Container wrapper sectionSm className={styles.container}>
      {children}
    </Container>
  )
}

export default PostGrid
